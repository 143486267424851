import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, BarSeries } from '@syncfusion/ej2-react-charts';
class Bar extends React.PureComponent {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         selectedMeasurement: Object.keys(props.data)[0],
    //     };
    // }

    render() {
        const { data } = this.props;
        const primaryyAxis = {
            title: 'Items',
            labelFormat: '{value}'
        };

        return (
            <div>
                <ChartComponent primaryXAxis={{ valueType: 'Category' }} primaryYAxis={primaryyAxis}  title="SLO Metrics Chart">
                    <Inject services={[BarSeries, Category, DataLabel, Tooltip, Legend]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data} xName="key" yName="value" type="Bar">
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        );
    }
}

export default Bar;