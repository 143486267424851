import React, { useState, useEffect } from 'react';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css'
import { useStateContext } from '../contexts/ContextProvider';
import { CiCircleRemove } from 'react-icons/ci';
import axios from 'axios';


const SubscriptionBuilder = ({ _subscription, _teams, onSubmit }) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [teams, setTeams] = useState(_teams ? _teams : []);
  // const [selectedTeam, setSelectedTeam] = useState(null)
  const [subscription, setSubscription] = useState({
    query: _subscription.query,
    emails: _subscription.emails || JSON.parse(localStorage.getItem('loggedInUser'))["emailAddress"],
    name: _subscription.name || '',
    entity: _subscription.entity,
    id: _subscription.id,
    team: _teams[0],
    // schedule: null
  });
  // const [schedule, setSchedule] = useState([]);
  const { currentColor } = useStateContext();
  const [errorMessage, setErrorMessage] = useState('')


  const handleSubmit = (event) => {
    axios.post(`${API_ENDPOINT}/api/incidents/subscribe`, subscription)
      .then((response) => {
        console.log("Successfully added new subscription!");
        if (onSubmit) {
          onSubmit();
        }
      }).catch((err) => {
        console.log(err);
      }
      )
  }

  const handleInputChange = (event) => {
    setSubscription({
      ...subscription,
      name: event.target.value
    });
    setErrorMessage(""); // Clear any error message
  };




  // useEffect(() => {
  //   setSubscription((prevSubscription) => ({
  //     ...prevSubscription,
  //     queryId
  //   }));
  // }, [queryId]);


  const handleEnterEmail = (event) => {
    if (event.key === 'Enter') {
      const newEmail = event.target.value.trim(); // Trim whitespace
      //check if newEmail is a valid email address
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (re.test(newEmail)) {
        // create set from comma seperated string subscription.email
        // convert comma seperated  string to array and add newEmail to it

        const emails = new Set([...subscription.emails.split(','), newEmail])

        // convert emails  back to string with commas in between

        setSubscription({
          ...subscription,
          emails: Array.from(emails).join(","), // Create a new array with spread operator
        });
        document.getElementsByName("email")[0].value=''
      }
    }
  };



  const handleRemoveEmail = (email) => {

    const emails = new Set([...subscription.emails.split(',')])

    emails.delete(email);

    setSubscription({
      ...subscription,
      emails: Array.from(emails).join(",")
    });
    // const index = subscription.email.findIndex(obj => obj === email); // Find index in original array
    //     if (index > -1) {
    //         subscription.email.splice(index, 1); // Remove from original array
    //         setSubscription({ ...subscription }); // Update state with the modified array
    //     }
    // setSubscription(prevSubscription => ({
    //     ...prevSubscription,
    //     email: prevSubscription.email.filter(item => item !== email) // Directly filter the original email array
    //   }));
  }

  return (
    <div >
      <div >
        <h2 className="text-2xl mb-4">Subscribe Query</h2>
        <div className='sm:text-left'>


          <div
            class="left-aligned-form">

            {/* Name input */}
            <div className='mt-1'>
              <label>
                Name:
                <input
                  type="text"
                  // value={inputNameValue}
                  name="name"
                  onChange={handleInputChange}
                  className={`border ${errorMessage ? 'border-red-500' : ''} ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"`}
                  placeholder="Name for subscription"

                />
              </label>
            </div>

            {/* Dropdown for teams */}
            <lable>
              Select a team to subscribe to:

              <div className="mt-1">
                <select
                  value={subscription.team}
                  onChange={(e) => setSubscription({ ...subscription, team: e.target.value })}
                  className="ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Select a Team</option>
                  {teams.map((team) => (
                    <option key={team} value={team}>
                      {team}
                    </option>
                  ))}
                </select>
              </div>
            </lable>


            {/* Email input */}
            <div className='mt-1'>
              <label>
                Email:
                <input
                  type="text"
                  // value={inputEmailValue}
                  name="email"
                  className={`border ${errorMessage ? 'border-red-500' : ''}   ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"`}
                  placeholder="Enter email id"
                  onKeyDown={handleEnterEmail}
                />
              </label>
            </div>

            {/* Email Container div */}
            <div class="grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24 mt-2 block">
              {subscription.emails && (
                subscription.emails?.split(',').map((email, index) => (
                  <>
                    <div
                      style={{ borderColor: currentColor }}
                      className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 max-w-fit"
                      key={index} // Add key prop for performance
                    >
                      <h1 style={{ color: currentColor }}>{email}</h1>
                      <button
                        onClick={() => handleRemoveEmail(email)}
                        className="ml-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none"
                      >
                        <CiCircleRemove />
                      </button>
                    </div>
                  </>
                ))
              )}

              {/* cron for schedule */}
            </div>

            {/* <div className='mt-2'>
          <Cron 
          name="schedule"
          value={schedule}
          onChange={handleInputChange}  
          setValue={(value) => {
        console.log('Schedule', value);
        setSchedule(value);
        
      }} />
          </div> */}

            <button
              style={{ backgroundColor: currentColor }}
              className=" text-white font-semibold py-2 px-4 rounded mt-4"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBuilder;
