import React, { useEffect, useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject, Search, Toolbar, Selection } from '@syncfusion/ej2-react-grids';

function UserList({ pageSize, onUserSelected, refresh }) {
    pageSize = pageSize === undefined ? 15 : pageSize;
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        fetch(`${API_ENDPOINT}/api/users`)
            .then((response) => response.json())
            .then((data) => setUsers(data))
            .catch((error) => console.error('Error fetching data:', error));
    }, [API_ENDPOINT, refresh]);

    const toolbarOptions = ['Search'];

    const [grid, setGrid] = useState();
    const created = () => {
        document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
            grid.search(event.target.value);
        });
    };

    return (

        <div className="p-6">
            <GridComponent dataSource={users} allowPaging={true} pageSettings={{ pageSize: pageSize }}
                ref={g => setGrid(g)} rowSelected={(data) => onUserSelected ? onUserSelected(data.data) : ''} toolbar={toolbarOptions} created={created}>
                <ColumnsDirective>
                    <ColumnDirective field="atlassianId" headerText="Atlassian ID" />
                    <ColumnDirective field="firstName" headerText="First Name" />
                    <ColumnDirective field="lastName" headerText="Last Name" />
                    <ColumnDirective field="emailAddress" headerText="Email Address" />
                    <ColumnDirective field="canAccessVerlake" headerText="Can Access Verlake" template={(props) => (
                        <input
                            type="checkbox"
                            checked={props.canAccessVerlake}
                            disabled={true}
                            className={props.canAccessVerlake ? 'green-checkbox' : ''}
                        />
                    )} />
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection]} />
            </GridComponent>
        </div>
    );
}

export default UserList;
