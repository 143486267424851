import React, { useCallback, useEffect, useState } from 'react'
import MenuDropdown from '../../components/MenuDropdown';
import ModalComponent from '../../components/ModalComponent';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import TeamOrchestration from './TeamOrchestration';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const Teams = () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [teams, setTeams] = useState([]);
    const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
    const [teamToEdit, setTeamToEdit] = useState(undefined);

    const toolbarOptions = ['Search'];

    const options = [
        {
            label: 'Create Team',
            action: () => {
                setTeamToEdit(undefined)
                setIsCreateTeamModalOpen(true);
            }
        }
    ];


    const closeCreateTeamModal = () => {
        setIsCreateTeamModalOpen(false);
    };

    const fetchTeams = useCallback(() => {
        fetch(`${API_ENDPOINT}/api/teams`)
            .then((response) => response.json())
            .then((data) => setTeams(data.data))
            .catch((error) => console.error('Error fetching data:', error));
    }, [API_ENDPOINT])

    const createTeamModalContent = (
        <TeamOrchestration team={teamToEdit} onTeamCreation={() => { fetchTeams.apply(); setIsCreateTeamModalOpen(false) }} />
    );

    useEffect(() => {
        fetchTeams();
    }, [fetchTeams]);

    const handleEditTeam = (team) => {
        console.log(team)
        setTeamToEdit(team);
        setIsCreateTeamModalOpen(true);
    }

    const handleDeleteTeam = (team) => {
        if (window.confirm(`Are you sure you want to delete the ${team.name} team?`)) {
            console.log(team);
            axios.delete(`${API_ENDPOINT}/api/teams/${team.id}`)
                .then((response) => {
                    fetchTeams.apply();
                    toast('Team ' + team.name + ' deleted successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast('An error occurred while deleting the team.', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark", // Use a different theme for error messages
                    });
                });
        }
    };

    return (
        <div className="p-6">

            {/* Dropdown */}
            <div className="flex">
                <h2 className="text-2xl font-bold mb-4">Teams</h2>
                <span className="justify-end right-11 absolute">
                    <MenuDropdown options={options}></MenuDropdown>
                </span>
                <ModalComponent isOpen={isCreateTeamModalOpen} onClose={closeCreateTeamModal}
                    content={createTeamModalContent} />
            </div>
            <GridComponent dataSource={teams} allowPaging={true} pageSettings={{ pageSize: 15 }} toolbar={toolbarOptions}>
                <ColumnsDirective>
                    <ColumnDirective field="id" headerText="ID" width="50" />
                    <ColumnDirective field="name" headerText="Name" width="100" />
                    <ColumnDirective field="description" headerText="Description" width="100" />
                    <ColumnDirective field="manager" headerText="Manager" width="100" template={(props) => (
                        <span>
                            {props.manager?.firstName} {props.manager?.lastName}
                        </span>
                    )} />
                    <ColumnDirective field="manager" headerText="Users" width="30" template={(props) => (
                        <span>
                            {props.users.length}
                        </span>
                    )} />
                    <ColumnDirective headerText="Actions" width="100" template={(props) => (
                        <span>
                            <button
                                className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none mr-2"
                                onClick={() => handleEditTeam(props)}
                            >
                                <FiEdit></FiEdit>
                            </button>
                            <button
                                className="bg-red-500 text-white p-2 rounded hover:bg-red-600 focus:outline-none"
                                onClick={() => handleDeleteTeam(props)}
                            >
                                <MdOutlineDeleteOutline></MdOutlineDeleteOutline>
                            </button>
                        </span>
                    )}></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Group, Search, Toolbar]} />
            </GridComponent>
            <ToastContainer />
        </div>
    )
}

export default Teams