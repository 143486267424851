import React, { useState, useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';


const JiraReport = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // const API_ENDPOINT = `https://2a52-49-205-35-230.ngrok-free.app`;
  const { currentColor } = useStateContext();

  const getTimeInMillis = (daysBefore) => {
    const now = Date.now();

    const millisecondsPerDay = 1000 * 60 * 60 * 24;

    const pastDateInMilliseconds = now - (daysBefore * millisecondsPerDay);

    return pastDateInMilliseconds;
  }

  function getPreviousMonthTime() {
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();

    // Handle January as a special case
    if (month === 0) {
      month = 11;
      year--;
    } else {
      month--;
    }

    const firstDayOfPreviousMonth = new Date(year, month, 1);
    const lastDayOfPreviousMonth = new Date(year, month + 1, 0);
    lastDayOfPreviousMonth.setHours(23, 59, 59, 999)

    const previousMonthStartEpoch = firstDayOfPreviousMonth.getTime();
    const previousMonthEndEpoch = lastDayOfPreviousMonth.getTime();

    return {
      start: previousMonthStartEpoch,
      end: previousMonthEndEpoch
    };
  }

  const options = [
    { id: 1, value: 'Last 30 Days', label: 'Last 30 Days' },
    { id: 2, value: 'Previous Month', label: 'Previous Month' },
  ];

  const [selectedOption, setSelectedOption] = useState('Last 30 Days');
  const [downloadUrl, setDownloadUrl] = useState(`${API_ENDPOINT}/api/atlassian/jira/report?startTimestamp=${getTimeInMillis(30)}&endTimestamp=${Date.now()}`);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    let url = '';
    if (event.target.value === 'Last 30 Days') {
      url = `${API_ENDPOINT}/api/atlassian/jira/report?startTimestamp=${getTimeInMillis(30)}&endTimestamp=${Date.now()}`;
    } else if (event.target.value === 'Previous Month') {
      url = `${API_ENDPOINT}/api/atlassian/jira/report?startTimestamp=${getPreviousMonthTime().start}&endTimestamp=${getPreviousMonthTime().end}`;
    }
    setDownloadUrl(url);
  };


  return (
    <div>
      <div className="bg-gray-100 p-6 rounded-lg shadow-lg">

        <h2 className="text-2xl font-semibold mb-4">Jira Report</h2>
        <div className="flex">
          <div className="mb-2">
            <select className="ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={selectedOption}
              onChange={handleOptionChange}>
              {options.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
              <button style={{ backgroundColor: currentColor }}
                className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
              // onClick={handleButtonClick}
              >Download Report</button>
            </a>

          </div>
        </div>
      </div>
    </div>
  );
};

export default JiraReport;