import React, { useEffect, useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject, Search, Toolbar, Selection } from '@syncfusion/ej2-react-grids';
import axios from 'axios';

function UseCaseList({ pageSize, onUseCaseSelected, refresh }) {
    pageSize = pageSize === undefined ? 15 : pageSize;
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [useCases, setUseCases] = useState([]);

    const fetchUseCases = () => {
        axios.get(`${API_ENDPOINT}/api/use-case/list`)
            .then((response) => setUseCases(response.data.data))
            .catch((error) => console.error('Error fetching data:', error));
    }

    useEffect(() => {
        fetchUseCases();
    }, [])

    const toolbarOptions = ['Search'];

    const [grid, setGrid] = useState();
    const created = () => {
        document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
            grid.search(event.target.value);
        });
    };

    return (

        <div className="p-6">
            <GridComponent dataSource={useCases} allowPaging={true} pageSettings={{ pageSize: pageSize }}
                ref={g => setGrid(g)} rowSelected={(data) => onUseCaseSelected ? onUseCaseSelected(data.data) : ''} toolbar={toolbarOptions} created={created}>
                <ColumnsDirective>
                    <ColumnDirective field="name" headerText="Title" />
                    <ColumnDirective field="description" headerText="Description" />
                    <ColumnDirective field="version" headerText="Version" />
                    {/* <ColumnDirective field="canAccessVerlake" headerText="Can Access Verlake" template={(props) => (
                        <input
                            type="checkbox"
                            checked={props.canAccessVerlake}
                            disabled={true}
                            className={props.canAccessVerlake ? 'green-checkbox' : ''}
                        />
                    )} /> */}
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection]} />
            </GridComponent>
        </div>
    );
}

export default UseCaseList;
