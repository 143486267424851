import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { useStateContext } from '../contexts/ContextProvider';

const Loader = (props) => {
    const { currentColor } = useStateContext();
    return (
        <div className={props.divClass || "min-h-screen flex items-center justify-center"}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color={currentColor}
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}

export default Loader