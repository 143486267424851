import React, { useCallback, useEffect, useState } from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject, Search, Toolbar, Selection } from '@syncfusion/ej2-react-grids';
import axios from 'axios';

const IncidentRouteKeyList = ({pageSize, onRouteKeySelected}) => {
  pageSize = pageSize === undefined ? 15 : pageSize;
  const toolbarOptions = ['Search'];
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [grid, setGrid] = useState();
  const [routeKey, setRouteKey] = useState([]);

  const created = () => {
    document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
        grid.search(event.target.value);
    });
};

const convertToMap = (data) => {
  const routingKeys = []
  for (let index = 0; index < data.length; index++) {
    routingKeys.push({
      'key': data[index]
    })
  }
  // data.foreach((key) => {
  //   routingKeys['key'] = key
  // })
  console.log(routingKeys)
  return routingKeys;
}

const fetchRouteKey = useCallback(() => {
  axios.get(`${API_ENDPOINT}/api/incidents/routing-keys`)
      .then((response) => setRouteKey(convertToMap(response.data.data)))
      .catch((error) => console.error('Error fetching data:', error));
}, [API_ENDPOINT])

useEffect(() => {
  fetchRouteKey();
}, [fetchRouteKey]);
  return (
    <div>
      <div className="p-6">
      <GridComponent dataSource={routeKey} allowPaging={true} pageSettings={{ pageSize: pageSize }}
        ref={g => setGrid(g)} rowSelected={(data) => onRouteKeySelected ? onRouteKeySelected(data.data.key) : ''} toolbar={toolbarOptions} created={created}>
        {/* <ColumnsDirective>
        {routeKey?.map(
        (columnName, index) => (
        <ColumnDirective
                      key={index}
                      // field={columnName}
                      headerText={'key'}
                      template={(props) => (
                        <span style={{ cursor: "pointer" }}>
                          {columnName}
                        </span>
                      )}
                      width="100"
                    />
        )
      )}
          
        </ColumnsDirective> */}
        <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection]} />
      </GridComponent>
    </div>
    </div>
  )
}

export default IncidentRouteKeyList