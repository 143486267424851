import React, { useCallback, useEffect, useState } from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject, Search, Toolbar, Selection } from '@syncfusion/ej2-react-grids';
import axios from 'axios';

const BitBucketRepoList = ({ pageSize, onRepoSelected }) => {

  pageSize = pageSize === undefined ? 15 : pageSize;
  const toolbarOptions = ['Search'];
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  const [grid, setGrid] = useState();
  const created = () => {
    document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
      grid.search(event.target.value);
    });
  };

  const [repos, setRepos] = useState([]);

  const fetchRepos = useCallback(() => {
    axios.get(`${API_ENDPOINT}/api/atlassian/bitbucket/repos`)
      .then((response) => setRepos(response.data.data))
      .catch((error) => console.error('Error fetching data:', error));
  }, [API_ENDPOINT])

  useEffect(() => {
    fetchRepos();
  }, [fetchRepos]);



  return (
    <div className="p-6">
      <GridComponent dataSource={repos} allowPaging={true} pageSettings={{ pageSize: pageSize }}
        ref={g => setGrid(g)} rowSelected={(data) => onRepoSelected ? onRepoSelected(data.data) : ''} toolbar={toolbarOptions} created={created}>
        <ColumnsDirective>
          <ColumnDirective field="id" headerText="ID" />
          <ColumnDirective field="name" headerText="Name" />
          <ColumnDirective field="reviewBranch" headerText="Review Branch" />
          <ColumnDirective field="deploymentBranch" headerText="Deployment Branch" />
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection]} />
      </GridComponent>
    </div>
  )
}

export default BitBucketRepoList