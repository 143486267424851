import React, { useEffect, useState } from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject, Search, Toolbar, Selection, ColumnChooser } from '@syncfusion/ej2-react-grids';
import EntityQueryBuilder from '../../components/QueryBuilder';
import Loader from '../../components/Loader';
import MenuDropdown from "../../components/MenuDropdown";
import ModalComponent from "../../components/ModalComponent";
import { useStateContext } from '../../contexts/ContextProvider';
import InputFormComponent from '../../components/InputForm';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { TbFileDownload } from "react-icons/tb";
import SubscriptionBuilder from '../../components/SubscriptionBuilder';
import axios from 'axios';




export default function IncidentSlo({pageSize, onUserSelected}) {
    pageSize = pageSize === undefined ? 15 : pageSize;
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [incidents, setIncidents] = useState(undefined);
    const [query, setQuery] = useState(undefined);
    const [incidentReport, setIncidentReport] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [showQueryBuilderModal, setShowQueryBuilderModal] = useState(false);
    const [showInputModal, setShowInputModal] = useState(false)
    const [showTeamIncident, setShowTeamIncident] = useState(false);
    const [reportGrid,setReportGrid]=useState([]);
    const [teamIncidents,setTeamIncidents]=useState([]);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const { currentColor } = useStateContext();

    
    const handleEditQuery = (props) => {
      console.log("Query edited:", props.query);
      setQuery(props.query)
      setShowQueryBuilderModal(true)       
           };

    const handleDeleteQuery = (props) => {
      console.log("Query deleted:", props.query);
      if (window.confirm(`Are you sure you want to delete the ${props.query.name} query?`)) {
        // console.log(team);
        axios.delete(`${API_ENDPOINT}/api/query/${props.query.id}`)
          .then((response) => {
            window.alert(`Query ${props.query.name} Deleted Successfully`);
          })
          .catch((error) => {
            console.error(error);
            
          });
      }
      setShowQueryBuilderModal(false);
      setShowInputModal(false);

    }
    const handleSubscribe = (props) => {
      console.log("Query subscribed:", props.query);
      setQuery(props.query);
      setShowSubscriptionModal(true);
      
      // <SubscriptionBuilder queryId = {query.id} onQueryCreated = {handleQueryCreated}/>
    }
    const SubscriptionBuilderComp = (
      <>
      <div style={{maxHeight:'20rem', overflowY:'auto'}}>

      <SubscriptionBuilder _subscription = {{query, entity:'Incident'}} _teams={reportGrid} onSubmit={()=> {setShowSubscriptionModal(false)}}/>
      </div>
      </>
    );
    
   
    const handleQueryCreated = (query) => {
        // Do something with the created query
        console.log("Query created:", query);
        setShowQueryBuilderModal(false); // Close the modal after query creation if needed
    };

    const initQuery = [{
        "id": 3,
        "query": "{\"combinator\":\"and\",\"rules\":[{\"id\":\"ce3627ab-c630-41ef-b46c-f9d81a9f2bc9\",\"field\":\"severity\",\"operator\":\"=\",\"valueSource\":\"value\",\"value\":\"CRITICAL\"}],\"id\":\"5675ad01-b4bf-4368-9547-99e8b10803bf\"}",
        "entity": "Incident",
        "active": true,
        "createdBy": null,
        "updatedBy": null,
        "createdAt": 1709325488626,
        "updatedAt": 1709325488626
    }];

    const getHumanReadableDate =  (timestamp) => {
      // create date object from timestamp in millis
      
      const date = new Date(timestamp);

      console.log(date)

      // Format the date into a human-readable format
      return new Intl.DateTimeFormat("en-US", {
        dateStyle: "long",
        timeStyle: 'medium'
      }).format(date);
    };

    const fetchIncidentReport = (fresh)   => {
      fetch(`${API_ENDPOINT}/api/incidents/report?refresh=${fresh}`)
            .then((response) => response.json())
            .then((data) => {setReportGrid(data.data.columns);setIncidentReport(data.data); setIsLoading(false)})
            .catch((error) => {console.error('Error fetching data:', error); setIsLoading(false)});
    }

    useEffect(() => {
        fetchIncidentReport(false)
    }, []);

    const toolbarOptions = ['Search', 'ColumnChooser'];
    
    const[grid, setGrid] = useState();
    const created = () => {
        document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
            grid.search(event.target.value);
        });
    };
    

    const onQuery = (queryCondition) => {
      console.log('query recieved', queryCondition);
      const changedQuery = {
        ...query,
        entity: 'Incident',
        condition: queryCondition
      }
      setQuery(changedQuery);
    } 

    const onSaveQuerySubmit = (name)  => {
      saveQuery({...query, name})
    }

    const saveQuery = (query) => {
      setShowInputModal(false);
        fetch(`${API_ENDPOINT}/api/query`, {
            method: 'POST',
            body: JSON.stringify(query),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })
              .then((response) => response.json())
              .then((data) => {
                fetchIncidentReport(true)
              })
              .catch((error) => {console.error('Error fetching data:', error); setIsLoading(false)});
    }

    const testQuery = () => {
        setIsLoading(true);
        fetch(`${API_ENDPOINT}/api/incidents`, {
          method: 'POST',
          body: JSON.stringify(query),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
            .then((response) => response.json())
            .then((data) => {setIncidents(data.data); setIsLoading(false)})
            .catch((error) => {console.error('Error fetching data:', error); setIsLoading(false)});
    }

    const openInputModal =  () => {
      setShowInputModal(true);
    }
    const closeInputModal = () => {
      setShowInputModal(false);
    }

    const saveQueryComp = (
      <>
      <InputFormComponent header='Name the query'  placeholder='Enter Query Name...' onSubmit={onSaveQuerySubmit} buttonLabel='Save'/>
      {closeInputModal}
      </>
    );

    const queryBuilderComp = (
        <>
        <div style={{maxHeight:'20rem', overflowY:'auto'}}>
            <h2 style={{ marginBottom: "2em" }}>Build Query</h2>
            <EntityQueryBuilder entity='incident.Incident' query={query?.condition} queryCreated={onQuery} querySubmitButtonLabel='Test Query' />
            <button 
        style={{ backgroundColor: currentColor }}
        className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
        onClick={testQuery}>Test Query</button>
        <button 
        style={{ backgroundColor: currentColor }}
        className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
        onClick={()=> setShowInputModal(true)}
        >
            Save Query
            </button>
        </div> 
        
        <div className="p-6"  style={{maxHeight:'20rem', overflowY:'auto'}}>
       
    {!isLoading ? <GridComponent 
    dataSource={incidents} 
    allowPaging={true} 
    pageSettings={{ pageSize: pageSize }}
  ref={g => setGrid(g)} 
  rowSelected={(data) => onUserSelected ? onUserSelected(data.data) : ''} 
  toolbar={toolbarOptions} created={created}
  showColumnChooser={true}>
      <ColumnsDirective>
          <ColumnDirective field="service" headerText="Service" />
          <ColumnDirective field="severity" headerText="Severity" />
          <ColumnDirective field="routingKey" headerText="Routing Key" />
          <ColumnDirective field="resolutionTime" headerText="Resolution Time" />
      
      </ColumnsDirective>
      <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection, ColumnChooser]} />
  </GridComponent> : <Loader/>}
</div> 


        </>
    );

    const teamIncidentListComp   =  (
      <GridComponent dataSource={teamIncidents} allowPaging={true} pageSettings={{ pageSize: pageSize }}
    ref={g => setGrid(g)} rowSelected={(data) => onUserSelected ? onUserSelected(data.data) : ''} toolbar={toolbarOptions} created={created}
    showColumnChooser={true}>
        <ColumnsDirective>
            <ColumnDirective field="service" headerText="Service" />
            <ColumnDirective field="severity" headerText="Severity" />
            <ColumnDirective field="createdAt" headerText="Created At" />
            <ColumnDirective field="acknowledgedAt" headerText="Acknowledged Date" />
            <ColumnDirective field="acknowledgedBy" headerText="Acknowledged By" />
            <ColumnDirective field="status" headerText="Status" />
              
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection, ColumnChooser]} />
    </GridComponent>
    )

    const handleCellClick = (args) => {
      if (args === undefined) {
        console.log("Hello");
        setShowTeamIncident(false)
      } 
      else
      if (args.cellIndex.cellIndex > 0 ) 
      {
        console.log('args', incidentReport.data[args.data.query.id][reportGrid[args.cellIndex.cellIndex-1]])
        setTeamIncidents(incidentReport.data[args.data.query.id][reportGrid[args.cellIndex.cellIndex-1]])
        setShowTeamIncident(true)

        }
      }

  return (
    <>
    <div className="p-6">
    <span className="justify-end right-11 absolute">
              <MenuDropdown
                options={[
                  {
                    label: "Add Query",
                    action: () => {
                      setQuery(undefined)
                      setShowQueryBuilderModal(true);
                        // handleEditQuery();
                    },
                  },
                  {
                    label: "Refresh Report",
                    action: () => {
                        fetchIncidentReport(true);
                    },
                  },
                     ]}
              ></MenuDropdown>
              </span>
               {showQueryBuilderModal && (
        <ModalComponent 
        isOpen={() => setShowQueryBuilderModal(true)}
        onClose={() => setShowQueryBuilderModal(false)} 
        content={queryBuilderComp}
        />
        
      )}

{showTeamIncident && (
        <ModalComponent 
        isOpen={() => setShowTeamIncident(true)}
        onClose={() => setShowTeamIncident(false)} 
        content={teamIncidentListComp}
        />
        
      )}
      {showSubscriptionModal && (
        <ModalComponent 
        isOpen={() => setShowSubscriptionModal(true)}
        onClose={() => setShowSubscriptionModal(false)} 
        content={SubscriptionBuilderComp}
        />
        
      )}
      {showInputModal && (
        <ModalComponent 
        isOpen={() => setShowInputModal(true)}
        onClose={() => setShowInputModal(false)} 
        content={saveQueryComp}
        />
        
      )}
            
    </div>
    
    <div className="p-6">

      {incidentReport && <h2>This report was last refreshed at {getHumanReadableDate(incidentReport?.freshAsOf)}</h2>}
       
    {!isLoading ? <GridComponent 
    dataSource={incidentReport?.report} 
    allowPaging={true} pageSettings={{ pageSize: pageSize }}
  ref={g => setGrid(g)} 
  rowSelected={(data) => onUserSelected ? onUserSelected(data.data) : ''} 
  toolbar={toolbarOptions} 
  created={created}
  cellSelected={handleCellClick}
            selectionSettings={{ allow: true, mode: "Cell" }}
            showColumnChooser={true}>
      
      <ColumnsDirective>
      <ColumnDirective
                field="name"
                headerText="Measurement"
                width="200"
              />
              {reportGrid?.map(
        (columnName, index) => (
        <ColumnDirective
                      key={index}
                      // field={columnName}
                      headerText={columnName}
                      template={(props) => (
                        <span style={{ cursor: "pointer" }}>
                          {props['rowData'][columnName]}
                        </span>
                      )}
                      width="60"
                    />
        )
      )}
      <ColumnDirective headerText="Actions" width="100" template={(props) => (
                        <span>
                            <button
                                className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none mr-2"
                                onClick={() => handleEditQuery(props)}
                            >
                                <FiEdit></FiEdit>
                            </button>
                            <button
                                className="bg-red-500 text-white p-2 rounded hover:bg-red-600 focus:outline-none mr-2"
                                onClick={() => handleDeleteQuery(props)}
                            >
                                <MdOutlineDeleteOutline></MdOutlineDeleteOutline>
                            </button>
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none"
                                onClick={() => handleSubscribe(props)}
                            >
                                <TbFileDownload />
                            </button>
                        </span>
                    )}></ColumnDirective>
      </ColumnsDirective>
      <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection, ColumnChooser]} />
  </GridComponent> : <Loader/>}
</div> 
</>
  )
}
