import React, { useState, useEffect, useCallback } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import { GrAddCircle } from 'react-icons/gr';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

function UserSearch() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    const [searchQuery, setSearchQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUsers = useCallback(async (query) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_ENDPOINT}/api/users/query?q=${query}`);
            const userData = response.data;
            setUsers(userData);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    }, [API_ENDPOINT]);

    useEffect(() => {
        const handleSearch = async (query) => {
            fetchUsers(searchQuery)
        };

        const delayDebounceFn = setTimeout(() => {
            if (searchQuery) {
                handleSearch(searchQuery);
            }
        }, 500); // 500ms debounce time

        return () => clearTimeout(delayDebounceFn); // Cleanup timeout on component unmount or searchQuery change
    }, [searchQuery, fetchUsers]);

    const handleChange = (e) => {
        setSearchQuery(e.target.value);
        // handleSearch(e.target.value);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchUsers(searchQuery);
        }
    };

    const handleEmailChange = (e, userIndex) => {
        const updatedUsers = [...users];
        updatedUsers[userIndex].emailAddress = e.target.value;
        setUsers(updatedUsers);
    };

    const handleAccessCheckboxChange = (userIndex) => {
        const updatedUsers = [...users];
        updatedUsers[userIndex].canAccessVerlake = !updatedUsers[userIndex].canAccessVerlake;
        setUsers(updatedUsers);
    }

    const isValidEmailAddress = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    const handleAddUser = (user) => {
        if (user.emailAddress == null || user.emailAddress === undefined || user.emailAddress.length === 0 || !isValidEmailAddress(user.emailAddress)) {
            toast('Valid Email is mandatory !', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        // Send a POST request to the API with the user data as the request payload
        axios.post(`${API_ENDPOINT}/api/users/import`, user)
            .then((response) => {
                // Handle success, if needed
                console.log('User added successfully:', response.data);
                toast('User Succesfully Imported !', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch((error) => {
                // Handle error, if needed
                console.error('Error adding user:', error);
            });
    };

    const handleRemoveUser = (user) => {

        axios.delete(`${API_ENDPOINT}/api/users/delete/${user.accountId}`)
            .then((response) => {
                // Handle success, if needed
                console.log('User added removed:', response.data);
                toast('User Succesfully Removed !', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch((error) => {
                // Handle error, if needed
                console.error('Error adding user:', error);
            });
    };



    return (
        <div className="container mx-auto">
            <div className="mb-4 flex items-center">
                <input
                    type="text"
                    placeholder="Search users"
                    className="p-2 border border-gray-300 rounded-l focus:outline-none w-full"
                    value={searchQuery}
                    onChange={handleChange}
                    onKeyDown={handleEnterKeyPress}
                />
                {/* <button
                    onClick={handleSearch}
                    className="bg-blue-500 text-white p-2 rounded-r hover:bg-blue-600 focus:outline-none"
                >
                    <BsSearch/>
                </button> */}
            </div>
            <ToastContainer />

            {isLoading ? (
                <div><Loader divClass="max-h-20 flex items-center justify-center" /></div>
            ) : (
                <div>
                    {users.length > 0 ? (
                        <div className="text-justify">
                            <GridComponent dataSource={users} allowPaging={true} pageSettings={{ pageSize: 10 }}>
                                <ColumnsDirective>
                                    <ColumnDirective field="accountId" headerText="Account ID" width='100' />
                                    <ColumnDirective field="emailAddress" headerText="Email Address"
                                        template={(props) => (
                                            <input
                                                type="text"
                                                className="border border-gray-300 rounded px-2 py-1 w-full focus:outline-none"
                                                value={props.emailAddress}
                                                onChange={(e) => handleEmailChange(e, props.index)}
                                            />
                                        )} width='100' />
                                    <ColumnDirective field="displayName" headerText="Display Name" width='100' />
                                    {/* <ColumnDirective field="active" headerText="Active" width='100' /> */}
                                    <ColumnDirective field="source" headerText="Source" width='100' />
                                    <ColumnDirective headerText="Can Access Verlake" width="100" template={(props) => (
                                        <input
                                            type="checkbox"
                                            checked={props.canAccessVerlake}
                                            onChange={(e) => handleAccessCheckboxChange(props.index)}
                                        />
                                    )}>
                                    </ColumnDirective>
                                    <ColumnDirective headerText="Actions" width="100" template={(props) => (
                                        props.existing ?
                                            <button
                                                className="bg-red-500 text-white p-2 rounded hover:bg-red-600 focus:outline-none"
                                                onClick={() => handleRemoveUser(props)}
                                            >
                                                <MdOutlineDeleteOutline></MdOutlineDeleteOutline>
                                            </button>
                                            :
                                            <button
                                                className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none"
                                                onClick={() => handleAddUser(props)}
                                            >
                                                <GrAddCircle></GrAddCircle>
                                            </button>
                                    )}>
                                    </ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Page, Sort, Filter, Group]} />
                            </GridComponent>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            )}
        </div>
    );
}

export default UserSearch;
