import React from "react";
import { AiOutlineFileSync } from "react-icons/ai";
import { IoMdAnalytics } from "react-icons/io";
import { IoStatsChart } from "react-icons/io5";
import { FaChartLine, FaListAlt, FaUserAlt } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { TbRulerMeasure } from "react-icons/tb";
import { SiJirasoftware } from "react-icons/si";

export const links = [
  {
    title: "Reports",
    links: [
      {
        name: "Git Analytics",
        path: "git-analytics",
        icon: <IoMdAnalytics />,
      },
      {
        name: "SLO Report (Soure Control)",
        path: "slo-report",
        icon: <TbRulerMeasure />,
      },
      {
        name: "SLO Report (Incidents)",
        path: "incident-slo",
        icon: <TbRulerMeasure />,
      },
      {
        name: "Jira Report",
        path: "jira-report",
        icon: <SiJirasoftware />,
      },
    ],
  },
  {
    title: "Ingestion",
    links: [
      {
        name: "Jobs",
        path: "ingestion-jobs",
        icon: <AiOutlineFileSync />,
      },
    ],
  },
  {
    title: "Monitoring",
    links: [
      {
        name: "Use Cases",
        path: "use-cases",
        icon: <FaListAlt />,        
      },
      // {
      //   name: "Dashboard",
      //   path: "use-case-dashboard",
      //   icon: <FaChartLine/>,
        
      // },
      {
        name: "Dashboards",
        path: "use-case-dashboards",
        icon: <FaChartLine/>,
        
      },
    ],
  },
  {
    title: "Users",
    links: [
      {
        name: "Users",
        path: "users",
        icon: <FaUserAlt />,
      },
      {
        name: "Teams",
        path: "teams",
        icon: <HiUserGroup />,
      },
    ],
  },
  {
    title: "System",
    links: [
      {
        name: "Stats",
        path: "stats",
        icon: <IoStatsChart />,
      },
    ],
  },
];
