import React, { useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider';

export default function InputFormComponent({header, onSubmit, placeholder, buttonLabel}) {
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('')

    const { currentColor } = useStateContext(); // get the color value from context provider

    const handleChange = (event) => {
      setInputValue(event.target.value);
      setErrorMessage('') // reset the error message when the input value changes
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // validate the input value here, e.g.,
      if (inputValue.length < 3) {
        setErrorMessage('Input value must be at least 3 characters long')
        return
      }
      if (onSubmit)
      {
        onSubmit(inputValue);
          console.log(`Input value: ${inputValue}`);
        }
          setInputValue('');

      // Handle the input value here, e.g., console.log(inputValue)
    };

  return (
    <>
    <div>
        <h2>{header}</h2>
        <form onSubmit={handleSubmit} style={{color: currentColor}}>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder || "Enter your text here"}
        className={`border ${errorMessage ? 'border-red-500' : ''}`} // add a class for styling the input field when there's an error
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>} {/* display the error message */}
      <button 
      style={{ backgroundColor: currentColor }}
        className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4" 
        type="submit">
            {buttonLabel || "Submit"}
            </button>
    </form>
    </div>
    </>
  )
}
