import React, { useState } from 'react'
import Loader from '../../components/Loader';


function GitAnalytics() {
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const handleIframeLoad = () => {
        console.log('iframe loaded')
        // This function will be called when the iframe has finished loading
        setIframeLoaded(true);
    };

    return (
        <div>
            {!iframeLoaded && (
                <Loader />
            )}
            <iframe width="100%" height="100%" title='Jira Looker Report'
                src="https://lookerstudio.google.com/embed/reporting/a0ffa15f-9c86-4579-b157-c6d75016d964/page/1eCVD"
                frameBorder="0" style={{ border: 0, position: 'absolute' }} allowFullScreen onLoad={handleIframeLoad}></iframe>
        </div>
    )
}

export default GitAnalytics