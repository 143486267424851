import React, { useState } from 'react'
import UserList from './UserList'
import { CiCircleRemove } from 'react-icons/ci'
import { useStateContext } from '../../contexts/ContextProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa'
import './TeamOrchestration.css'
import JiraProjectsList from '../../components/atlassian/JiraProjectsList'
import BitBucketRepoList from '../../components/atlassian/BitBucketRepoList'
import IncidentRouteKeyList from '../../components/projects/IncidentRouteKeyList'

const TeamOrchestration = ({ team, onTeamCreation }) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { currentColor } = useStateContext();
    var isEditing = false

    const [users, setUsers] = useState(team ? team.users : []);
    const [managers, setManagers] = useState(team ? [team.manager] : []);
    const [teamName, setTeamName] = useState(team ? team.name : '');
    const [teamDescription, setTeamDescription] = useState(team ? team.description : '');
    const [jiraProjects, setJiraProjects] = useState(team && team.jiraProjects ? team.jiraProjects : []);
    const [bitBucketRepos, setBitBucketRepos] = useState(team && team.repos ? team.repos : [])
    const [incidents, setIncidents] = useState(team && team.routingKeys ? team.routingKeys : [])
    const totalSlides = 5;
    const [slideNo, setSlideNo] = useState(0);

    const goToNextSlide = () => {
        if (slideNo === 0) {
            if (managers && managers[0] && teamName.trim()) {
                // Proceed with logic if both managers and teamName are valid
                setSlideNo(slideNo + 1);
            } else {
                // Handle validation errors
                if (!teamName.trim()) {
                    showToast("Please enter a Team Name");
                } else {
                    showToast("Please select a Manager");
                }
            }
        } else if (slideNo < totalSlides - 1) {
            setSlideNo(slideNo + 1);
        }
    };

    const goToPreviousSlide = () => {
        if (slideNo > 0) {
            setSlideNo(slideNo - 1)
        }
    }

    if (team) {
        isEditing = true;
    }

    const handleUserSelection = (user) => {
        const _users = JSON.parse(JSON.stringify(users))
        const index = _users.findIndex(obj => obj.atlassianId === user.atlassianId);
        if (index === -1) {
            _users.push(user);
        }
        setUsers(_users);
    }
    const handleManagerSelection = (manager) => {

        setManagers([manager]);
    }

    const handleRemoveUser = (user) => {
        const _users = JSON.parse(JSON.stringify(users))
        const index = _users.findIndex(obj => obj.atlassianId === user.atlassianId);
        if (index > -1) {
            _users.splice(index, 1);
        }
        setUsers(_users);
    }
    const handleRemoveManager = (manager) => {

        setManagers(null);

    }

    const onJiraProjectSelected = (jiraProject) => {
        const _jiraProjects = JSON.parse(JSON.stringify(jiraProjects))
        const index = _jiraProjects.findIndex(obj => obj === jiraProject.key);
        if (index === -1) {
            _jiraProjects.push(jiraProject.key);
        }
        setJiraProjects(_jiraProjects);
    }

    const handleRemoveJiraProject = (jiraProject) => {
        const _jiraProjects = JSON.parse(JSON.stringify(jiraProjects))
        const index = _jiraProjects.findIndex(obj => obj === jiraProject);
        if (index > -1) {
            _jiraProjects.splice(index, 1);
        }
        setJiraProjects(_jiraProjects);
    }

    const onBitBucketRepoSelected = (repo) => {
        const _bitBucketRepos = JSON.parse(JSON.stringify(bitBucketRepos))
        const index = _bitBucketRepos.findIndex(obj => obj.id === repo.id);
        if (index === -1) {
            _bitBucketRepos.push(repo);
        }
        setBitBucketRepos(_bitBucketRepos);
    }

    const handleRemoveBitBucketRepo = (repo) => {
        const _bitBucketRepos = JSON.parse(JSON.stringify(bitBucketRepos))
        const index = _bitBucketRepos.findIndex(obj => obj.id === repo.id);
        if (index > -1) {
            _bitBucketRepos.splice(index, 1);
        }
        setBitBucketRepos(_bitBucketRepos);
    }

    const onIncidentSelection = (incident) => {
        const _incident = JSON.parse(JSON.stringify(incidents))
        const index = _incident.findIndex(obj => obj === incident);
        if (index === -1) {
            _incident.push(incident);
        }
        setIncidents(_incident);
    }

    const handleRemoveIncidents = (incident) => {
        const _incident = JSON.parse(JSON.stringify(incidents))
        const index = _incident.findIndex(obj => obj === incident);
        if (index > -1) {
            _incident.splice(index, 1);
        }
        setIncidents(_incident);
    }

    const showToast = (toastMsg) => {
        toast(toastMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const saveTeam = () => {
        const teamPayload = {
            name: teamName,
            description: teamDescription,
            manager: managers[0],
            users: users,
            jiraProjects: jiraProjects,
            repos: bitBucketRepos,
            routingKeys: incidents
        }
        var axiosRequest;
        if (isEditing) {
            teamPayload.id = team.id
            axiosRequest = axios.put(`${API_ENDPOINT}/api/teams`, teamPayload);
        } else {
            axiosRequest = axios.post(`${API_ENDPOINT}/api/teams`, teamPayload)
        }

        axiosRequest.then((response) => {
            onTeamCreation();
            const toastMsg = isEditing ? 'Team Updated successfully' : 'Team Created successfully'
            showToast(toastMsg)
        })
            .catch((error) => {
                console.error('Error adding user:', error.response.data.message);
                showToast(error.response.data.message)
            });
    }

    const populateJiraProjects = () => {
        axios.post(`${API_ENDPOINT}/api/teams/calculateJiraProjectsForTeam`, users)
            .then((response) => {
                const _jiraProjects = JSON.parse(JSON.stringify(jiraProjects))
                let wasAdded = false;
                response.data.data.forEach(jiraProject => {
                    const index = _jiraProjects.findIndex(obj => obj === jiraProject);
                    if (index === -1) {
                        wasAdded = true
                        _jiraProjects.push(jiraProject);
                    }
                });
                setJiraProjects(_jiraProjects);
                if (wasAdded) {
                    showToast('Suggested Jira projects added')
                } else {
                    showToast('Suggested projects already in the list')
                }
            })
    }

    const populateBitBucketRepos = () => {
        axios.post(`${API_ENDPOINT}/api/teams/calculateReposForTeam`, users)
            .then((response) => {
                const _bitBucketRepos = JSON.parse(JSON.stringify(bitBucketRepos))
                let wasAdded = false;
                response.data.data.forEach(repo => {
                    const index = _bitBucketRepos.findIndex(obj => obj.id === repo.id);
                    if (index === -1) {
                        wasAdded = true
                        _bitBucketRepos.push(repo);
                    }
                });
                setBitBucketRepos(_bitBucketRepos);
                if (wasAdded) {
                    showToast('Suggested Bitbucket Repos Added')
                } else {
                    showToast('Suggested repos already in the list')
                }
            })
    }

    return (
        <div style={{ minHeight: '520px', minWidth: '800px' }}>
            <h2 style={{ marginBottom: '2em' }}>{isEditing ? 'Update' : 'Create'} Team</h2>

            {/* Team Name */}
            {slideNo === 0 &&
                <>
                    <h2 className="left-0 mb-4 text-left">Enter Team Name</h2>
                    <input
                        type="text"
                        placeholder="Team Name"
                        className="p-2 border border-gray-300 rounded-l focus:outline-none w-full mb-2"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                    />

                    <h2 className="left-0 mb-4 text-left mt-5">Enter Team Description</h2>
                    <textarea className="p-2 border border-gray-300 rounded-l focus:outline-none w-full mb-2"
                        value={teamDescription}
                        onChange={(e) => setTeamDescription(e.target.value)}
                        placeholder="Team of Architects..."></textarea>

                    {/* Add Manager */}
                    <h2 className="left-0 mb-4 text-left">Add Manager</h2>
                    <div class="grid grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24">
                        {managers && managers.length > 0 && (
                            <div style={{ borderColor: currentColor }} className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 max-w-fit" title={managers[0].emailAddress}>
                                <h1 style={{ color: currentColor }}>{managers[0].firstName} {managers[0].lastName}</h1>
                                <button onClick={() => handleRemoveManager(managers[0])} className="ml-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none">
                                    <CiCircleRemove></CiCircleRemove>
                                </button>
                            </div>
                        )}
                    </div>

                    <UserList pageSize={6} onUserSelected={handleManagerSelection} />
                </>}

            {/* Add Users */}
            {slideNo === 1 && <div style={{ animation: 'slideInFromRight 0.1s ease-in-out' }}>
                <h2 className="left-0 mb-4 text-left">Add Users</h2>
                <div class="grid grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24">
                    {users && users.map((user, index) => (
                        user && (<>
                            <div style={{ borderColor: currentColor }} className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 max-w-fit" title={user.emailAddress}>
                                <h1 style={{ color: currentColor }}>{user?.firstName} {user?.lastName}</h1>
                                <button onClick={() => handleRemoveUser(user)} className="ml-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none">
                                    <CiCircleRemove></CiCircleRemove>
                                </button>
                            </div>
                        </>)))}
                </div>

                <UserList pageSize={6} onUserSelected={handleUserSelection} />
            </div>}

            {/* Add Jira Projects */}
            {slideNo === 2 && <>
                <h2 className="left-0 mb-4 text-left">Add Jira Projects</h2>
                <div class="grid grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24">
                    {jiraProjects && jiraProjects.map((jiraProject, index) => (
                        jiraProject && (<>
                            <div style={{ borderColor: currentColor }} className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 max-w-fit">
                                <h1 style={{ color: currentColor }}>{jiraProject}</h1>
                                <button onClick={() => handleRemoveJiraProject(jiraProject)} className="ml-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none">
                                    <CiCircleRemove></CiCircleRemove>
                                </button>
                            </div>
                        </>)))}
                </div>
                <button
                    style={{ backgroundColor: currentColor }}
                    className=" text-white font-semibold py-2 px-4 rounded right-30 absolute bottom-2"
                    onClick={() => { populateJiraProjects(); }}
                    title="Auto Populate from Suggestions based on selected Users"
                >
                    Auto Populate
                </button>
                <JiraProjectsList pageSize={6} onProjectSelected={onJiraProjectSelected}></JiraProjectsList>
            </>}

            {/* Add Git Repos */}
            {slideNo === 3 && <>
                <h2 className="left-0 mb-4 text-left">Add Git Repos</h2>
                <div class="grid grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24">
                    {bitBucketRepos && bitBucketRepos.map((bitBucketRepo, index) => (
                        bitBucketRepo && (<>
                            <div style={{ borderColor: currentColor }} className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 max-w-fit">
                                <h1 style={{ color: currentColor }}>{bitBucketRepo.name}</h1>
                                <button onClick={() => handleRemoveBitBucketRepo(bitBucketRepo)} className="ml-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none">
                                    <CiCircleRemove></CiCircleRemove>
                                </button>
                            </div>
                        </>)))}
                </div>
                <button
                    style={{ backgroundColor: currentColor }}
                    className=" text-white font-semibold py-2 px-4 rounded right-30 absolute bottom-2"
                    onClick={() => { populateBitBucketRepos(); }}
                    title="Auto Populate from Suggestions based on selected Users"
                >
                    Auto Populate
                </button>
                <BitBucketRepoList pageSize={6} onRepoSelected={onBitBucketRepoSelected}></BitBucketRepoList>
            </>}

            {slideNo === 4 && <>
                <h2 className="left-0 mb-4 text-left">Add Incidents</h2>
                <div class="grid grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24">
                    {incidents && incidents.map((incidents, index) => (<>
                        <div style={{ borderColor: currentColor }} className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 max-w-fit">
                            <h1 style={{ color: currentColor }}>{incidents}</h1>
                            <button onClick={() => handleRemoveIncidents(incidents)} className="ml-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none">
                                <CiCircleRemove></CiCircleRemove>
                            </button>
                        </div>
                    </>))}
                </div>

                <IncidentRouteKeyList pageSize={6} onRouteKeySelected={onIncidentSelection}></IncidentRouteKeyList>
            </>}

            {/* Navigation */}
            {slideNo > 0 && <button className="absolute p-4 right-0 bottom-0 left-4 text-xl" style={{ color: currentColor, maxWidth: '5rem' }} onClick={goToPreviousSlide}>
                <FaLessThan />
            </button>}
            {slideNo < (totalSlides - 1) && <button className="absolute p-4 right-0 bottom-0 text-xl" style={{ color: currentColor, maxWidth: '5rem' }} onClick={goToNextSlide}>
                <FaGreaterThan />
            </button>}

            {slideNo === totalSlides - 1 && <button
                style={{ backgroundColor: currentColor }}
                className=" text-white font-semibold py-2 px-4 rounded absolute right-2 bottom-2"
                onClick={() => { saveTeam(); }}
            >
                {isEditing ? 'Update' : 'Save'}
            </button>}
        </div>
    )
}

export default TeamOrchestration

