import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject, Search, Toolbar, Selection } from '@syncfusion/ej2-react-grids';

const JiraProjectsList = ({ pageSize, onProjectSelected }) => {
    pageSize = pageSize === undefined ? 15 : pageSize;
    const toolbarOptions = ['Search'];
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const [projects, setProjects] = useState([]);

    const [grid, setGrid] = useState();
    const created = () => {
        document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
            grid.search(event.target.value);
        });
    };

    const fetchJiraProjects = useCallback(() => {
        axios.get(`${API_ENDPOINT}/api/projects/jira`)
            .then((response) => setProjects(response.data.data))
            .catch((error) => console.error('Error fetching data:', error));
    }, [API_ENDPOINT])

    useEffect(() => {
        fetchJiraProjects();
    }, [fetchJiraProjects]);


    return (
        <div className="p-6">
            <GridComponent dataSource={projects} allowPaging={true} pageSettings={{ pageSize: pageSize }}
                ref={g => setGrid(g)} rowSelected={(data) => onProjectSelected ? onProjectSelected(data.data) : ''} toolbar={toolbarOptions} created={created}>
                <ColumnsDirective>
                    <ColumnDirective field="key" headerText="Project Key" />
                    <ColumnDirective field="name" headerText="Project Name" />
                    <ColumnDirective field="source" headerText="Source" />
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection]} />
            </GridComponent>
        </div>
    )
}

export default JiraProjectsList