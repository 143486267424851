import React, { useEffect } from 'react';
import { SiShopware } from 'react-icons/si';
import { FaAtlassian } from 'react-icons/fa';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {

        const query = queryString.parse(location.search);

        if (query) {
            if (query.error) {
                console.error('OAuth2 Error:', query.error_description);
                navigate('/login'); // Redirect to an error page
                return;
            }
            // Check if an authorization code is present
            else if (query.code) {
                axios.get(`${API_ENDPOINT}/api/users/loginWithAtlassian?code=${query.code}`)
                    .then((response) => {
                        // Store the access token securely (e.g., in local storage or a secure cookie)
                        const user = response.data;

                        localStorage.setItem('loggedInUser', JSON.stringify(user.data.user));
                        localStorage.setItem('jiraURL', user.data.jiraURL);
    
                        // Redirect to the home page or another protected route
                        // window.location = window.location.protocol + '//' + window.location.host;
                        window.location.href = '/';
                    })
                    .catch((error) => {
                        var errorMessage = 'Error During Login. Please Contact Administrator';
                        if (error.response.status === 404) {
                            errorMessage = error.response.data.message;
                        }
                        toast(errorMessage, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        return;
                        // navigate('/login'); // Redirect to an error page
                    });
            }
        }


    }, [API_ENDPOINT, location.search, navigate]);

    const redirectToAtlassianLogin = () => {
        window.location.href = `${API_ENDPOINT}/api/users/loginWithAtlassian`;
    };

    return (
        <div className="w-full h-screen flex items-start">
            <div className="relative w-1/2 h-full flex flex-col">
                <div className="absolute top-[35%] left-[10%] flex flex-col">
                    <SiShopware className="text-4xl" />
                    <h1 className="text-6xl font-extrabold my-4" style={{ fontFamily: 'SpaceGrotesk, sans-serif' }}>Verlake</h1>
                    <p className="text-xl font-normal text-[#40444b]" style={{ fontFamily: 'SpaceGrotesk, sans-serif' }}>Visualize, track, and manage progress across Jira projects and teams.</p>
                </div>
                {/* <img src={loginpagebanner} className="w-full h-full object-cover opacity-10"></img> */}
            </div>

            <div className="w-1/2 h-full bg-[#f5f5f5] flex flex-col p-14">
                <div className="min-h-screen flex items-center justify-center bg-gray-100">
                    <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
                        <h2 className="text-2xl font-semibold text-center mb-4">Login</h2>

                        <div className="text-center">
                            {/* Atlassian Login Button */}
                            <button
                                className="border-black border bg-white hover:bg-gray-100 text-black py-2 px-4 rounded-lg flex items-center justify-center w-full"
                                onClick={redirectToAtlassianLogin}
                            >
                                <FaAtlassian className="text-blue-500 text-xl mr-2" /> Continue with Atlassian
                            </button>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login