import React, { useEffect, useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Sort, Page } from '@syncfusion/ej2-react-grids';
import Loader from '../../components/Loader';
import { BiRefresh } from "react-icons/bi";
import { useStateContext } from '../../contexts/ContextProvider';




const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const getCurrentMonth = () => {
    const currentDate = new Date();
    return months[currentDate.getMonth()];
}

const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
}
const years = [...Array(getCurrentYear() - 2020 + 1).keys()].map((x) => getCurrentYear() - x);

// Starting index for months based on startMonth
const getMonthIndex = (month) => {
    return months.indexOf(month);
}
// Filter years starting from startYear
const getFilteredYears = (startYear) => {
    return years.filter((year) => year >= startYear);
}

function Stats() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [systemStats, setSystemStats] = useState(undefined);
    // const [startDate, setStartDate] = useState(undefined);
    // const [endDate, setEndDate] = useState(undefined);
    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
    const [selectedYear, setSelectedYear] = useState(getCurrentYear());
    const [fromDateMonth, setFromDateMonth] = useState("March");
    const [fromDateYear, setFromDateYear] = useState("2023");
    const { currentColor } = useStateContext();

    const handleRefreshStat = () => {
        refreshStats(true)
    }

    const refreshStats = (fresh) => {
        // convert startDate and endDate to epoch value
        let startDate = new Date(selectedYear, months.indexOf(selectedMonth), 1);
        let endDate = new Date(selectedYear, months.indexOf(selectedMonth) + 1, 0);
        endDate.setHours(23, 59, 59, 999)

        // startDate = Math.floor(startDate.getTime() / 1000);
        // endDate = Math.floor(endDate.getTime() / 1000);

        startDate = startDate.getTime();
        endDate = endDate.getTime();

        fetch(`${API_ENDPOINT}/api/system/stats?startTimestamp=${startDate}&endTimestamp=${endDate}&fresh=${fresh}`)
            .then((response) => response.json())
            .then((data) => setSystemStats(data))
            .catch((error) => console.error('Error fetching data:', error));
    }

    useEffect(() => {
        refreshStats(false);
    }, [API_ENDPOINT, selectedMonth, selectedYear]);


    const handleYearChange = (event) => {
        console.log(`Selected year: ${event.target.value}`);
        setSelectedYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        console.log(`Selected month: ${event.target.value}`);
        setSelectedMonth(event.target.value);
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const getEpochForMonthStart = () => {
        // Create a Date object for the first day of the selected month and year
        const date = new Date(selectedYear, months.indexOf(selectedMonth), 1);


        // Get epoch time in milliseconds
        const epochTime = date.getTime();
        return epochTime;
    };


    return (
        <>

            {systemStats ? <div>
                <div className="bg-gray-100 p-6 rounded-lg shadow-lg">

                    <h2 className="text-2xl font-semibold mb-4">Monthy Stats</h2>
                    <div className="flex">
                        <div className="mb-2">
                            <select value={selectedMonth} onChange={handleMonthChange} className="ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                {months.map((month, index) => (
                                    <option key={month} value={month} disabled={selectedYear === fromDateYear ? index < getMonthIndex(fromDateMonth) : null}>
                                        {month}
                                    </option>
                                ))}

                            </select>
                        </div>
                        <div className="mb-2">
                            <select value={selectedYear} onChange={handleYearChange} className="ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                {getFilteredYears(fromDateYear).map((year) => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-2 ml-2">
                            <button
                                style={{ backgroundColor: currentColor }}
                                className="bg-red-500 text-white p-2 rounded hover:bg-red-600 focus:outline-none"
                                onClick={() => handleRefreshStat()}
                                title="Refresh Stats"
                            >
                                <BiRefresh />
                            </button>
                        </div>
                    </div>
                    {formatDate(systemStats.startDate)} - {formatDate(systemStats.endDate)}

                    {
                        formatDate(systemStats.startDate) === formatDate(getEpochForMonthStart()) ?
                            <div>

                                <div className="mt-6">
                                    {/* <h3 className="text-xl font-semibold">Commits Stats</h3> */}
                                    <p className="mt-2"><span className="font-semibold">Total Commits:</span> {systemStats.commitsStats.total}</p>
                                </div>

                                {/* <div className="mt-6">
                    <h3 className="text-xl font-semibold">User Stats</h3>
                    <p className="mt-2"><span className="font-semibold">Active Users:</span> {systemStats.userStats.total}</p>
                </div> */}

                                <div className="mt-6">
                                    <h4 className="text-lg font-semibold">Active Users ( {systemStats.userStats.total} )</h4>
                                    <GridComponent dataSource={systemStats.userStats.users}>
                                        <ColumnsDirective>
                                            <ColumnDirective field="atlassianId" headerText="ID" />
                                            <ColumnDirective field="firstName" headerText="First Name" />
                                            <ColumnDirective field="lastName" headerText="Last Name" />
                                            <ColumnDirective field="emailAddress" headerText="Email Address" />
                                            {/* Add more columns as needed */}
                                        </ColumnsDirective>
                                        <Inject services={[Sort, Page]} />
                                    </GridComponent>
                                </div>
                            </div> :
                            <Loader />}

                </div>
            </div> :
                <Loader />
            }
        </>
    )
}

export default Stats