import React from 'react';
import Modal from 'react-modal';
import './ModalComponent.css'

Modal.setAppElement('#root');

const ModalComponent = ({ isOpen, onClose, content, minWidth }) => {
    const modalStyles = {

        content: {
            minWidth: minWidth,
            overflowY: 'auto',

        },
    }
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Example Modal"
            style={modalStyles}
            className="modal bg-white p-8 rounded shadow-lg"
        >
            <div className="text-center">
                <div className="flex justify-end">
                    <button
                        className="text-gray-600 hover:text-gray-900 transition duration-300"
                        title="Close"
                        onClick={onClose}
                    >
                        <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                {content}
            </div>
        </Modal>
    );
};

export default ModalComponent;