import React, { useState } from 'react';
import MenuDropdown from '../../components/MenuDropdown';
import ModalComponent from '../../components/ModalComponent';
import UserSearch from './UserSearch';
import UserList from './UserList';

function Users() {

    const [refresh, setRefresh] = useState(false);

    const options = [
        {
            label: 'Add User',
            action: () => {
                setIsNewUserModalOpen(true);
            }
        }
    ];

    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);

    const closeNewUserModal = () => {
        setIsNewUserModalOpen(false);
        setRefresh(!refresh);
    };

    const newUserModalContent = (
        <>
            <div className="min-h-screen" style={{width: '90em'}}>
                <h2 style={{ marginBottom: '2em' }}>Add User from Atlassian</h2>
                <UserSearch></UserSearch>

            </div>
        </>
    );

    return (

        <div className="p-6">

            {/* Dropdown */}
            <div className="flex">
                <h2 className="text-2xl font-bold mb-4">Users</h2>
                <span className="justify-end right-11 absolute">
                    <MenuDropdown options={options}></MenuDropdown>
                </span>
                <ModalComponent isOpen={isNewUserModalOpen} onClose={closeNewUserModal}
                    content={newUserModalContent} />
            </div>
            <UserList refresh={refresh} />
        </div>
    );
}

export default Users;
