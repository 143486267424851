import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import ModalComponent from '../../components/ModalComponent';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css'
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import MenuDropdown from '../../components/MenuDropdown';
import Loader from '../../components/Loader';
import { MdDownload } from 'react-icons/md';

const IngestionJob = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { currentColor } = useStateContext();
  const [jobData, setJobData] = useState(undefined);
  const [schedule, setSchedule] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDownloadError = (jobData) => {
    const exitMessage = jobData.exitMessage;
    const filename = `${jobData.jobName}.log`;
    const blob = new Blob([exitMessage], { type: 'text/plain' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const saveSchedule = () => {
    axios.post(`${API_ENDPOINT}/api/ingest/schedule?cron=0 ${schedule}`)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const modalContent = (
    <>
      <h2 style={{ marginBottom: '2em' }}>Set Ingestion Schedule</h2>
      <Cron value={schedule} setValue={(value) => {
        console.log('Schedule', value);
        setSchedule(value);
      }} />
      <button
        style={{ backgroundColor: currentColor }}
        className=" text-white font-semibold py-2 px-4 rounded mt-4"
        onClick={() => { saveSchedule(); closeModal(); }}
      >
        Save
      </button>
    </>
  );

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/ingest/jobs`);
        setJobData(response.data);
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    };

    const fetchScheduleData = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/ingest/schedule`);
        setSchedule(response.data.cron.slice(1).trim());
      } catch (error) {
        console.error('Error schedule data:', error);
      }
    };
    fetchJobData();
    fetchScheduleData();
  }, [API_ENDPOINT]);

  return (jobData ?
    <div className="p-6"
    // className="rounded-xl md:w-auto p-4 m-3"
    // style={{ backgroundColor: currentColor }}
    >
      <div className="flex">
        <h2 className="text-2xl font-bold mb-4">Ingestion Jobs</h2>
        <span className="justify-end right-11 absolute">
          <MenuDropdown options={[
            {
              label: 'Change Schedule',
              action: () => {
                openModal();
              }
            }
          ]}>

          </MenuDropdown>
        </span>
        <ModalComponent isOpen={isModalOpen} onClose={closeModal}
          content={modalContent} />
      </div>
      <div className="p-6">
        <GridComponent dataSource={jobData} allowPaging={true}>
          <ColumnsDirective>
            <ColumnDirective field='jobName' headerText='Job Name' width='100' />
            <ColumnDirective field='startTime' headerText='Start Time' width='100' />
            <ColumnDirective field='endTime' headerText='End Time' width='100' />
            <ColumnDirective field='status' headerText='Status' width='100' />
            <ColumnDirective headerText="Actions" width="100" template={(props) => (
              <span>
                {props.exitCode === "FAILED" && <button
                  className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none mr-2"
                  onClick={() => handleDownloadError(props)}
                >
                  <MdDownload />
                </button>}
              </span>
            )} >
            </ColumnDirective>
          </ColumnsDirective>

          <Inject services={[Page, Sort, Filter, Group]} />
        </GridComponent>
      </div>
    </div> : <Loader />
  );
}

export default IngestionJob