import React, { useEffect, useState } from 'react'
import UseCaseList from './UseCaseList'
import { CiCircleRemove } from 'react-icons/ci'
import { useStateContext } from '../../contexts/ContextProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa'
import { InputNumber } from 'antd'


const DashboardOrchestration = ({ dashboard, onDashboardCreation }) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { currentColor } = useStateContext();
    var isEditing = false

    const [useCases, setUseCases] = useState(dashboard ? dashboard.useCases : [])
    const [dashboardTitle, setDashboardTitle] = useState(dashboard ? dashboard.title : '')
    const [isPublicDashboard, setIsPublicDashboard] = useState(dashboard ? dashboard.isPublic : false)
    const [dashboardDescription, setDashboardDescription] = useState(dashboard ? dashboard.description : '')
    const totalSlides = 2;
    const [slideNo, setSlideNo] = useState(0);

    useEffect(() => {
        if (dashboard && dashboard.isPublic) {
            setIsPublicDashboard(true);
        } else {
            setIsPublicDashboard(false);
        }
    }, [dashboard]);

    const goToNextSlide = () => {
        if (slideNo === 0) {
            if (dashboardTitle.trim()) {
                setSlideNo(slideNo + 1);
            } else {
                // Handle validation errors
                if (!dashboardTitle.trim()) {
                    showToast("Dashboard Name is required. Please enter a valid name.");
                }
            }
        } else if (slideNo < totalSlides - 1) {
            setSlideNo(slideNo + 1);
        }
    };

    const goToPreviousSlide = () => {
        if (slideNo > 0) {
            setSlideNo(slideNo - 1)
        }
    }

    if (dashboard) {
        isEditing = true;
    }

    const showToast = (toastMsg) => {
        toast(toastMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }



    const handleUseCaseSelection = (useCase) => {
        const _useCase = useCases.slice();
        const index = _useCase.findIndex(obj => obj.id === useCase.id);
        if (index === -1) {
            _useCase.push(useCase);
        }
        setUseCases(_useCase);
    }

    const handleRemoveUseCase = (useCase) => {
        const _useCase = useCases.slice();
        const index = _useCase.findIndex(obj => obj.id === useCase.id);
        if (index > -1) {
            _useCase.splice(index, 1);
        }
        setUseCases(_useCase);
    }

    const saveDashboard = () => {
        const dashboardPayload = {
            title: dashboardTitle,
            description: dashboardDescription,
            useCases: useCases,
            isPublic: isPublicDashboard
        }
        var axiosRequest;
        if (isEditing) {
            dashboardPayload.id = dashboard.id
            axiosRequest = axios.post(`${API_ENDPOINT}/api/dashboard/save`, dashboardPayload);
        } else {
            axiosRequest = axios.post(`${API_ENDPOINT}/api/dashboard/save`, dashboardPayload)
        }

        axiosRequest.then((response) => {
            onDashboardCreation();
            const toastMsg = isEditing ? 'Dashboard Updated successfully' : 'Dashboard Created successfully'
            showToast(toastMsg)
        })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Error adding useCases:', error.response.data.message);
                    showToast(error.response.data.message);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Error adding useCases:', error.request);
                    showToast('Error adding useCases: No response received');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error adding useCases:', error.message);
                    showToast('Error adding useCases: ' + error.message);
                }
            });
    }

    const onChangeShowReportDay = (value) => {
        console.log('changed', value);
    };


    return (
        <div style={{ minHeight: '520px', minWidth: '800px' }}>
            <h2 className="font-bold" style={{ marginBottom: '2em' }}>{isEditing ? 'Update' : 'Create'} Dashboard</h2>

            {/* Team Name */}
            {slideNo === 0 &&
                <>
                    <h2 className="left-0 mb-4 text-left">Enter Dashboard Name</h2>
                    <input
                        type="text"
                        placeholder="Title"
                        className="p-2 border border-gray-300 rounded-l focus:outline-none w-full mb-2"
                        value={dashboardTitle}
                        onChange={(e) => setDashboardTitle(e.target.value)}
                    />

                    <h2 className="left-0 mb-4 text-left mt-5">Enter Dashboard Description</h2>
                    <textarea className="p-2 border border-gray-300 rounded-l focus:outline-none w-full mb-2"
                        value={dashboardDescription}
                        onChange={(e) => setDashboardDescription(e.target.value)}
                        placeholder="Description"></textarea>

                    {/* <div className="flex items-center mb-2">
                        <input
                            type="checkbox"
                            id="isPublic"
                            name="isPublic"
                            checked={isPublicDashboard}
                            onChange={(e) => {
                                setIsPublicDashboard(e.target.checked); console.log("toggle clicked")
                            }}
                        />
                        <label htmlFor="isPublic" className="ml-2">Make dashboard public</label>
                    </div> */}

                    <h5 className='  mb-1 flex justify-start'> Display Report for the Last &nbsp;<span>
                        <InputNumber size="small" min={1} max={90} defaultValue={30} onChange={onChangeShowReportDay} />
                    </span>&nbsp; days
                    </h5>
                </>}

            {/* Add Users */}
            {slideNo === 1 && <div style={{ animation: 'slideInFromRight 0.1s ease-in-out' }}>
                <h2 className="left-0 mb-4 text-left">Add Use Cases</h2>

                <div class="grid grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24">
                    {useCases && useCases.map((useCase, index) => (
                        useCases && (<>
                            <div style={{ borderColor: currentColor }} className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 max-w-fit" title={useCase.name}>
                                <h1 style={{ color: currentColor }}>{useCase?.name}</h1>
                                <button onClick={() => handleRemoveUseCase(useCase)} className="ml-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none">
                                    <CiCircleRemove></CiCircleRemove>
                                </button>
                            </div>
                        </>)))}

                </div>

                <UseCaseList pageSize={6} onUseCaseSelected={handleUseCaseSelection} />

            </div>}




            {/* Navigation */}
            {slideNo > 0 && <button className="absolute p-4 right-0 bottom-0 left-4 text-xl" style={{ color: currentColor, maxWidth: '5rem' }} onClick={goToPreviousSlide}>
                <FaLessThan />
            </button>}
            {slideNo < (totalSlides - 1) && <button className="absolute p-4 right-0 bottom-0 text-xl" style={{ color: currentColor, maxWidth: '5rem' }} onClick={goToNextSlide}>
                <FaGreaterThan />
            </button>}

            {slideNo === totalSlides - 1 && <button
                style={{ backgroundColor: currentColor }}
                className=" text-white font-semibold py-2 px-4 rounded absolute right-2 bottom-2"
                onClick={() => { saveDashboard(); }}
            >
                {isEditing ? 'Update' : 'Save'}
            </button>}
        </div>
    )
}

export default DashboardOrchestration