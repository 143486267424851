import React, { useEffect, useState } from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Selection, Inject, Search, ColumnChooser, Toolbar } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import Loader from '../../components/Loader';


const SLOActionLogs = () => {


    const getTimeInMillis = (daysBefore) => {
        // Get the current date in milliseconds
        const now = Date.now();

        // Convert days to milliseconds (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        const millisecondsPerDay = 1000 * 60 * 60 * 24;

        // Calculate the date in milliseconds before the specified number of days
        const pastDateInMilliseconds = now - (daysBefore * millisecondsPerDay);

        return pastDateInMilliseconds;
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [actionLog, setActionLog] = useState([])
    const [fromDate, setFromDate] = useState(7)

    const getActionLogs = () => {
        const fromDateInMillis = getTimeInMillis(fromDate)
        axios.get(`${API_ENDPOINT}/api/slo/actionLog?from=${fromDateInMillis}`)
            .then((response) => { console.log('response.data', response.data); setActionLog(response.data) })
            .catch((error) => {
                //   showToast(error.response.data.message)
            });
    }

    useEffect(() => {
        const fetchActionLogs = () => {
            return getActionLogs();
        }

        fetchActionLogs();

    }, [API_ENDPOINT])


    // Date filter
    const options = [
        { value: 7, label: 'Last week' },
        { value: 30, label: 'Last Month' },
        { value: 90, label: 'Last 3 months' },
    ];

    const handleChange = (event) => {
        setActionLog([])
        setFromDate(parseInt(event.target.value));
        getActionLogs()
    };

    const toolbarOptions = ['Search', 'ColumnChooser'];
    const [grid, setGrid] = useState();
    const created = () => {
        document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
            grid.search(event.target.value);
        });
    };


    return (
        <>
            <div>
                <select value={fromDate} onChange={handleChange}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            {actionLog.length > 0 ? <div>
                <GridComponent
                    dataSource={actionLog}
                    allowPaging={true}
                    pageSettings={{ pageSize: 10 }}
                    ref={g => setGrid(g)}
                    // rowSelected={(data) => onUserSelected ? onUserSelected(data.data) : ''}
                    toolbar={toolbarOptions} created={created}
                    showColumnChooser={true}>
                    <ColumnsDirective>
                        <ColumnDirective field="date" headerText="Date" width="20" />
                        <ColumnDirective field="metric" headerText="Violation" width="60" />
                        <ColumnDirective field="value" headerText="Item" width="20" />
                        <ColumnDirective field="action" headerText="Action" width="30" />

                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection, ColumnChooser]} />
                </GridComponent>
            </div> : <Loader/>}
        </>

    )
}

export default SLOActionLogs