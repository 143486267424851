import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';

import Loader from '../../components/Loader';

const DashboardTimeSeriesGraph = ({ _dashboardData = [], caller }) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [dashboardData, setDashboardData] = useState(_dashboardData);
    const [isLoading, setIsLoading] = useState(false)

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (_dashboardData !== dashboardData) {
    //             setDashboardData(_dashboardData);
    //         }
    //     }, 60000); // 60000ms = 1 minute

    //     return () => clearInterval(intervalId);
    // }, [_dashboardData]);


    const formatDate = (inputDate) => {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // Split the input date by the "-" delimiter
        const [year, month, day] = inputDate.split("-");

        // Convert month from number to month name
        const monthName = months[parseInt(month, 10) - 1];

        // Return the formatted date
        return `${day} ${monthName} ${year}`;
    }

    useEffect(() => {
        setIsLoading(true);
        if (dashboardData === _dashboardData) {
            setIsLoading(false);
        } else {
            setDashboardData(_dashboardData);
            setIsLoading(false);
        }
    }, [_dashboardData]);

    const clickCandleHandle = (useCaseId, day) => {
        const url = `${API_ENDPOINT}/api/use-case/${useCaseId}/run-logs/${day}`;
        window.open(url, '_blank');
        console.log('candle props:', useCaseId, day);
    }

    return (

        <div className="p-4 bg-gray-200 rounded-lg">

            <div className='flex justify-between'>
                {/* Dashboard Title */}
                <div className='flex justify-start mt-2'>
                    <h2 className="text-3xl font-bold text-center text-gray-600 mb-10">
                        {dashboardData?.dashboard?.title}
                    </h2>
                </div>
                {/* Legends Section */}
                {/* <div className="flex justify-end">
                    <div className="bg-white p-4 rounded-lg shadow-md">
                        <p className="text-lg font-semibold mb-2 text-gray-700">Legends</p>
                        <div className="flex items-center mb-2">
                            <div className="w-6 h-6 rounded-full bg-green-500 mr-2"></div>
                            <p className="text-gray-600">Success</p>
                        </div>
                        <div className="flex items-center mb-2">
                            <div className="w-6 h-6 rounded-full bg-red-500 mr-2"></div>
                            <p className="text-gray-600">Fail</p>
                        </div>
                        <div className="flex items-center">
                            <div className="w-6 h-6 rounded-full bg-yellow-500 mr-2"></div>
                            <p className="text-gray-600">Runtime Failure</p>
                        </div>
                    </div>
                </div> */}
            </div>


            {isLoading ? (
                <Loader />
            ) : dashboardData.report ? (
                <div className='ml-5 mr-5'>
                    {/* Use Cases */}
                    {/* <h3 className="text-2xl font-semibold text-gray-700 mb-6">Use Cases</h3> */}
                    {dashboardData?.report?.map((reportItem, index) => (
                        <div key={index} className="mb-8">
                            <h4 className="text-xl font-semibold text-gray-500 mb-1 flex justify-start">{reportItem.useCase.name}</h4>
                            <div className="overflow-x-auto">
                                <div className="flex items-center space-x-1 overflow-x-auto border border-gray-300 rounded-lg shadow-md p-1">
                                    {reportItem.result?.map((value, idx) => (
                                        <Tooltip title={`${value.uptime.toFixed(2)}% uptime on ${formatDate(value.day)}`}>
                                            <div
                                                key={idx}
                                                className={`w-2 h-12 cursor-pointer transition duration-300 rounded-full ${value.status === 0
                                                    ? 'bg-green-500 hover:bg-green-600'
                                                    : value.status === -1
                                                        // ? 'bg-yellow-500 hover:bg-yellow-600'
                                                        ? 'bg-red-500 hover:bg-red-600'
                                                        : 'bg-red-500 hover:bg-red-600'
                                                    }`}
                                                // title={value.errorMessage || ''}
                                                onClick={caller === "Public Dashboard" ? () => { } : () => clickCandleHandle(reportItem.useCase.id, value.day)}
                                            />
                                        </Tooltip>

                                    ))}
                                </div>

                                <div className="flex items-center">
                                    <div className="flex items-center text-gray-600">
                                        <text className="text-l font-semibold">{reportItem.daysOld}</text>  &nbsp;<text> days ago</text>
                                    </div>
                                    <div className="flex-1 border-t border-gray-300 mx-4"></div>
                                    <div className={`flex items-center text-gray-600 ${reportItem.averageUptime >= reportItem.useCase.targetUptime ? 'text-green-500' : 'text-red-500'}`}>
                                        <span className="text-l">
                                            {reportItem.averageUptime.toFixed(2)}
                                        </span>
                                        % average uptime
                                    </div>
                                    <div className="flex-1 border-t border-gray-300 mx-4"></div>
                                    <div className="flex items-center text-gray-600">
                                        Today
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>)
                :
                <Loader />}

        </div>
    );
};

export default DashboardTimeSeriesGraph;
