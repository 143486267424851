import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Navbar, Sidebar } from "./components";
import { IngestionJob, JiraReport, SLOMetrics } from "./pages";

import "./App.css";
import SideBar from "./components/SideBar";
import { useStateContext } from "./contexts/ContextProvider";
import AlertsRulesListing from "./pages/alerts/AlertsRulesListing";
import Users from "./pages/users/Users";
import Login from "./pages/users/Login";
import Stats from "./pages/system/Stats";
import Teams from "./pages/users/Teams";
import IncidentSlo from "./pages/incidents/IncidentSlo";
import UseCases from "./pages/monitoring/UseCase";
import Dashboard from "./pages/monitoring/Dashboard";
import GitAnalytics from "./pages/report/GitAnalytics";
import Dashboards from "./pages/monitoring/Dashboards";
import PublicDashboard from "./pages/monitoring/PublicDashboard";

const App = () => {
  const [authenticated, setAuthenticated] = useState(true);
  // const location = useLocation();

  const checkAuthentication = () => { return !!localStorage.getItem('loggedInUser'); };

  useEffect(() => {
    const isAuthenticated = checkAuthentication();

    setAuthenticated(isAuthenticated);
  }, []);

  const PrivateWrapper = ({ children }) => {
    return authenticated ? children : <Navigate to="/login" />;
  };

  const { activeMenu } = useStateContext();

  return (
    <div>
      <div>
        <BrowserRouter>
          <div className="flex relative dark:bg-main-dark-bg">
            {/* <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
                          <TooltipComponent content="Settings" position='Top'>
                              <button type='button' className='text-3xl p-3 hover:drop-shadow-xl
                     hover:bg-light-gray text-white' style={{
                                      background: 'blue',
                                      borderRadius: '50%'
                                  }}>
                                  <FiSettings />
                              </button>
                          </TooltipComponent>
                      </div> */}
            {authenticated &&
              (activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                  <SideBar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              ))}
            <div
              className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
                activeMenu && authenticated ? "md:ml-72" : "flex-2"
              }`}
            >
              {authenticated && (
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                  <Navbar />
                </div>
              )}

              <div>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/dashboard/:dashboardId" element={<PublicDashboard />} />
                  <Route
                    path="/"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<GitAnalytics />}
                      />
                    }
                  />

                  <Route
                    path="/git-analytics"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<GitAnalytics />}
                      />
                    }
                  />
                  <Route
                    path="/slo-report"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<SLOMetrics />}
                      />
                    }
                  />
                  <Route
                    path="/incident-slo"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<IncidentSlo />}
                      />
                    }
                  />
                  <Route
                    path="/jira-report"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<JiraReport />}
                      />
                    }
                  />

                  <Route
                    path="/ingestion-jobs"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<IngestionJob />}
                      />
                    }
                  />
                 
                  <Route
                    path="/use-cases"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<UseCases />}
                      />
                    }
                  />

                  {/* <Route
                    path="/use-case-dashboard"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<Dashboard/>}
                      />
                    }
                  /> */}
                  <Route
                    path="/use-case-dashboards"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<Dashboards />}
                      />
                    }
                  />
                   
                  <Route
                    path="/users"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<Users />}
                      />
                    }
                  />
                  <Route
                    path="/teams"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<Teams />}
                      />
                    }
                  />
                  <Route
                    path="/stats"
                    element={
                      <PrivateWrapper
                        authenticated={authenticated}
                        children={<Stats />}
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
