import React, { useEffect, useState } from 'react'
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { QueryBuilder, defaultOperators } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import './QueryBuilder.css'
import { toast } from 'react-toastify';
import { useStateContext } from '../contexts/ContextProvider';

// import { defaultOperators, toFullOption } from 'react-querybuilder';
// import { RuleGroupType } from 'react-querybuilder';


const initialQuery = { combinator: 'and', rules: [] };

const showToast = (toastMsg) => {
    toast(toastMsg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };



// const initialFields = (
//     [
//         {
//             "name": "id",
//             "label": "Id",
//             "placeholder": "Enter id",
//             "inputType": "text"
//         },
//         {
//             "name": "service",
//             "label": "Service",
//             "placeholder": "Enter service",
//             "inputType": "text"
//         },
//         {
//             "name": "alertCount",
//             "label": "Alert Count",
//             "placeholder": "Enter alertCount",
//             "inputType": "number"
//         },
//         {
//             "name": "platform",
//             "label": "Platform",
//             "placeholder": "Enter platform",
//             "inputType": "text"
//         },
//         {
//             "name": "title",
//             "label": "Title",
//             "placeholder": "Enter title",
//             "inputType": "text"
//         },
//         {
//             "name": "description",
//             "label": "Description",
//             "placeholder": "Enter description",
//             "inputType": "text"
//         },
//         {
//             "name": "severity",
//             "label": "Severity",
//             "placeholder": "Enter severity",
//             "inputType": "text"
//         },
//         {
//             "name": "createdAt",
//             "label": "Created At",
//             "placeholder": "Enter createdAt",
//             "inputType": "date"
//         },
//         {
//             "name": "acknowledgedAt",
//             "label": "Acknowledged At",
//             "placeholder": "Enter acknowledgedAt",
//             "inputType": "date"
//         },
//         {
//             "name": "acknowledgedBy",
//             "label": "Acknowledged By",
//             "placeholder": "Enter acknowledgedBy",
//             "inputType": "text"
//         },
//         {
//             "name": "resolvedAt",
//             "label": "Resolved At",
//             "placeholder": "Enter resolvedAt",
//             "inputType": "date"
//         },
//         {
//             "name": "resolvedBy",
//             "label": "Resolved By",
//             "placeholder": "Enter resolvedBy",
//             "inputType": "text"
//         },
//         {
//             "name": "monitorType",
//             "label": "Monitor Type",
//             "placeholder": "Enter monitorType",
//             "inputType": "text"
//         },
//         {
//             "name": "monitorName",
//             "label": "Monitor Name",
//             "placeholder": "Enter monitorName",
//             "inputType": "text"
//         },
//         {
//             "name": "incidentLink",
//             "label": "Incident Link",
//             "placeholder": "Enter incidentLink",
//             "inputType": "text"
//         },
//         {
//             name: 'gender',
//             label: 'Gender',
//             // operators: defaultOperators.filter((op) => op.name === '='),
//             valueEditorType: 'select',
//             values: [
//               { name: 'M', label: 'Male' },
//               { name: 'F', label: 'Female' },
//               { name: 'O', label: 'Other' },
//             ],
//           },
//         {
//             "name": "status",
//             "label": "Status",
//             "placeholder": "Enter status",
//             "inputType": "text"
//         }
//     ]
//   );

export default function EntityQueryBuilder({entity, queryCreated, query, querySubmitButtonLabel}) {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    
    const [_query, setQuery] = useState(query || initialQuery);

    const [fields, setFields] = useState([]);

    const { currentColor } = useStateContext();

    
    

    useEffect(() => {
        const transformField = (_fields) => {
            const updatedFields = _fields.map(field => {
                if (field.valueEditorType === "select") {
                    return {
                        ...field,
                        operators: defaultOperators.filter(op => (op.name === '=' || op.name === '!=')),
                    };
                }
                return field;
            });
            console.log('updated fields', updatedFields)
            setFields(updatedFields);
        }

        fetch(`${API_ENDPOINT}/api/query/fields/${entity}`)
            .then((response) => response.json())
            .then((data) => {transformField(data.data);})
            .catch((error) => {console.error('Error fetching data:', error); showToast('Error in fetching data')});

        // transformField(initialFields);

    }, [])

    const handleQueryChange = (query) => {
        setQuery(query);
        queryCreated(query);
    }
    
    
//   useEffect(() => {
    
//     if (entity) {
//       // fetch data from http://localhost:8080/api/query/fields/Incident and set to fields
//          .catch((error) => {console.error('Error fetching data:', error); showToast('Error in fetching data')});
//     }
//   }[API_ENDPOINT]);

  return (
    <div> 
        <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>

        <QueryBuilder fields={fields} query={_query} onQueryChange={handleQueryChange} showNotToggle/>
        {/* <button 
        style={{ backgroundColor: currentColor }}
        className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
        onClick={() => {console.log(JSON.stringify(_query));queryCreated(_query)}}>{querySubmitButtonLabel}</button> */}
        {/* <button 
        style={{ backgroundColor: currentColor }}
        className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
        // onClick={() => {console.log(JSON.stringify(_query));queryCreated(_query)}}
        >
            Save Query
            </button> */}
        </QueryBuilderDnD>
    </div>
  )
}
