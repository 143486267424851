import React, { useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { headerContent } from '@syncfusion/ej2-react-grids';

function FileUploader({ onUpload, accept, heading }) {
    const { currentColor } = useStateContext();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isUploadButtonEnabled, setIsUploadButtonEnabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [fileSizeError, setFileSizeError] = useState(null);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileSize = file.size / (1024 * 1024); // convert to MB

        if (fileSize > 10) {
            setFileSizeError('The maximum allowed size for a JAR file is 10 MB.');
            setIsUploadButtonEnabled(false);
        } else {
            setSelectedFile(file);
            setFileName(`Selected file: ${file.name}`);
            setIsUploadButtonEnabled(true);
            setFileSizeError(null);
        }
    };

    const handleUpload = () => {
        // Implement your file upload logic here
        // This example demonstrates using FormData for potential server-side interactions
        if (onUpload) {
            onUpload(selectedFile); // Pass the selected file to the onUpload handler
        } else {
            // Default upload logic using FormData (same as before)
            const formData = new FormData();
            formData.append('file', selectedFile);

        }

        // Send the FormData to your server-side endpoint using fetch or Axios
        // fetch('/upload', {
        //     method: 'POST',
        //     body: formData,
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         alert(`File uploaded successfully! ${data.message || ''}`); // Handle response from server
        //         setSelectedFile(null);
        //         setFileName('');
        //         setIsUploadButtonEnabled(false);
        //     })
        //     .catch((error) => {
        //         console.error('Error uploading file:', error);
        //         alert('File upload failed. Please try again.');
        //     });
    };


    const headingContent = heading || "Upload File";

    return (
        <div>
            <div className='p-6'>

                <h2>{headingContent}</h2>
            </div>
            <input
                type="file"
                id="myFile"
                name="filename"
                accept={accept}
                onChange={handleFileChange}
            />
            <br />
            <br />
            <p id="fileName">{fileName}</p>
            {fileSizeError && <p style={{ color: 'red' }}>{fileSizeError}</p>}

            <button
                style={{ backgroundColor: currentColor }}
                className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
                id="uploadBtn" disabled={!isUploadButtonEnabled} onClick={handleUpload}>
                Upload
            </button>
        </div>
    );
}

export default FileUploader;
