import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import Bar from '../../components/Charts/Bar';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Selection, Inject } from '@syncfusion/ej2-react-grids';
import ModalComponent from '../../components/ModalComponent';
import './SLOMetrics.css';
import Loader from '../../components/Loader';
import MenuDropdown from '../../components/MenuDropdown';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Cron from 'react-js-cron';
import SLOActionLogs from './SLOActionLogs';

const SLOMetrics = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [selectedVisType, setSelectedVisType] = useState('grid');
  const [data, setSLOMetricsData] = useState(null);
  const [dataForGrid, setDataForGrid] = useState([]);
  const [gridHeaders, setGridHeaders] = useState([]);
  const [selectedMeasurement, setSelectedMeasurement] = useState({});
  const [chartData, setChartData] = useState(undefined);
  const [selectedMeasurementReport, setSelectedMeasurementReport] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangeScheduleModalOpen, setIsChangeScheduleModalOpen] = useState(false);
  const [reportRefreshedDate, setReportRefreshedDate] = useState(undefined)
  const [schedule, setSchedule] = useState([]);
  const [isActionLogModalOpen, setIsActionLogModalOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}/api/slo/getSLOMetrics`);
        const metricResultResponse = await response.json();

        const date = new Date(metricResultResponse.sloMetricResult.freshAsOf);

        // Format the date into a human-readable format
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          dateStyle: 'long'
        }).format(date);


        console.log(formattedDate);

        setReportRefreshedDate(formattedDate);

        setSLOMetricsData(metricResultResponse.sloMetricResult.metricResults)

        const reportData = prepareDataForReport(metricResultResponse.sloMetricResult.metricResults);
        setDataForGrid(reportData.dataForGrid);

        setGridHeaders(reportData.projectsArray)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchReportRefreshSchedule = async () => {
      axios.get(`${API_ENDPOINT}/api/slo/schedule`)
        .then((response) => setSchedule(response.data.cron.slice(1).trim()))
        .catch((error) => {
          showToast(error.response.data.message)
        });
    }
    fetchData();
    fetchReportRefreshSchedule();
  }, [API_ENDPOINT]);



  const { currentColor } = useStateContext();
  const handleChange = (event) => {
    setSelectedMeasurement(event.target.value)
    if (data[event.target.value]) {
      setChartData(Object.entries(data[event.target.value].results).map(([key, value]) => ({
        key: key,
        value: value.length
      })))
    } else {
      setChartData(undefined);
    }
  };

  const modalContent = (selectedMeasurementReport.reportData &&
    <>
      <h2 className="mb-5">{selectedMeasurementReport.metric}</h2>
      <div className="grid-container">
        {selectedMeasurementReport.reportData.map((substring, index) => (substring.length > 0 && (
          <div key={index} className="grid-item">
            <a className="break-words" href={`${API_ENDPOINT}/api/redirectTo?type=${selectedMeasurementReport.type}&platform=${selectedMeasurementReport.platform}&path=${substring.trim()}`} target="_blank" rel="noreferrer">{substring.trim()}</a>
          </div>
        )))}
      </div>
      {/* <span>
        {selectedMeasurementReport.reportData.replace(/(;[^;]*;[^;]*;[^;]*;[^;]*;)/, '$1\n').replace(/;/g, ',')}
      </span> */}
    </>
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCellClick = (args) => {
    if (args.cellIndex.cellIndex > 0) {
      const reportData = data[args.data._id]['results'][gridHeaders[args.cellIndex.cellIndex - 1]]
      // const measurement = args.data['Measurement'];
      if (reportData) {
        setSelectedMeasurementReport({ reportData, ...data[args.data._id] });
        setIsModalOpen(true);
        // alert(reportData);
      }
    }
  };

  const showToast = (toastMsg) => {
    toast(toastMsg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const triggerSLORefresh = () => {
    axios.get(`${API_ENDPOINT}/api/slo/triggerSLOIngestion`)
      .then((response) => showToast('SLO refresh triggered'))
      .catch((error) => {
        showToast(error.response.data.message)
      });
  }

  const saveSchedule = () => {
    axios.post(`${API_ENDPOINT}/api/slo/schedule?cron=0 ${schedule}`)
      .then(res => {
        showToast('SLO refresh schedule updated.')
      })
      .catch(err => {
        console.log(err);
        showToast('Failed to update SLO refresh schedule.')
      });
  };

  const modalContentChangeSchedule = (
    <>
      <h2 style={{ marginBottom: '2em' }}>Set SLO Report Refresh Schedule</h2>
      <Cron value={schedule} setValue={(value) => {
        console.log('Schedule', value);
        setSchedule(value);
      }} />
      <button
        style={{ backgroundColor: currentColor }}
        className=" text-white font-semibold py-2 px-4 rounded mt-4"
        onClick={() => { saveSchedule(); closeModal(); }}
      >
        Save
      </button>
    </>
  );

  const modalSLOActionLog = (
    <>
      <h2 style={{ marginBottom: '2em' }}>SLO Action Log</h2>
      <SLOActionLogs />
    </>
  );

  return (dataForGrid != null ?
    <>
      {reportRefreshedDate &&
        <div className="flex">

          <div className="flex">
            <h2 className="text-2xl font-bold mb-4 p-6">SLO Report</h2>
            <span className="justify-end right-11 absolute">
              <MenuDropdown options={[
                {
                  label: 'Manual Trigger',
                  action: () => {
                    triggerSLORefresh()
                  }
                },
                {
                  label: 'Change Schedule',
                  action: () => {
                    setIsChangeScheduleModalOpen(true)
                  }
                },
                {
                  label: 'SLO Action Log',
                  action: () => {
                    setIsActionLogModalOpen(true)
                  }
                }
              ]}>

              </MenuDropdown>
            </span>
            <ModalComponent isOpen={isModalOpen} onClose={closeModal}
              content={modalContent} />
            <ModalComponent isOpen={isChangeScheduleModalOpen} onClose={() => setIsChangeScheduleModalOpen(false)}
              content={modalContentChangeSchedule} />
            <ModalComponent isOpen={isActionLogModalOpen} onClose={() => setIsActionLogModalOpen(false)}
              content={modalSLOActionLog} />
          </div>

          <div className="flext ml-96 mr-5 absolute mt-4">
            Report refreshed on <span>{reportRefreshedDate}</span>
          </div>
        </div>}
      <div className="mb-2">
        <select id="measurementDropdown" value={selectedVisType} onChange={(event) => setSelectedVisType(event.target.value)} className="ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="grid">
            Grid
          </option>
          <option value="bar">Bar</option>
        </select>
      </div>
      {selectedVisType === 'bar' && <div>
        <div className="flex ml-3">
          <div>
            <select id="measurementDropdown" value={selectedMeasurement} onChange={handleChange} className="mt-1 block w-760 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option>
                Select Measurement
              </option>
              {Object.keys(data).map((key) => (
                <option key={key} value={key}>
                  {data[key].metric}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div
        // className="rounded-2xl md:w-auto p-4 m-3"
        // style={{ backgroundColor: currentColor }}
        >
          {/* <div className="flex justify-between items-center">
          <p className="font-semibold text-white text-2xl">SLO Metrics</p>
        </div> */}

          {chartData && <div className="mt-4 w-full p-2 content-center">
            <Bar currentColor={currentColor} id="column-sparkLine" height="400px" type="Column" data={chartData} width="70%" color="rgb(242, 252, 253)" />
          </div>}
        </div>
      </div>}

      {selectedVisType === 'grid' && <div>
        <GridComponent dataSource={dataForGrid} cellSelected={handleCellClick} selectionSettings={{ allow: true, mode: 'Cell' }}>
          <ColumnsDirective>
            <ColumnDirective field="metric" headerText="Measurement" width="400" />
            {gridHeaders.map((columnName, index) => ((columnName !== '__Report' && columnName !== 'ID' && columnName !== 'metric') && (
              // <ColumnDirective key={index} field={columnName} headerText={columnName} width="100"/>
              <ColumnDirective key={index} field={columnName} headerText={columnName}
                template={(props) => (
                  <span style={{ cursor: 'pointer' }}>{props[columnName]}</span>
                )} width='100' />
            )))}
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter, Group, Selection]} />
        </GridComponent>
      </div>}
      <ModalComponent isOpen={isModalOpen} onClose={closeModal}
        content={modalContent} />
      <ToastContainer />
    </> : <Loader />
  )
}

function prepareDataForReport(metricResult) {
  const metricResultArray = [];
  const projects = new Set();

  for (const key in metricResult) {
    const data = metricResult[key];
    const { metric, results } = data;

    Object.keys(results).map(project => {
      return projects.add(project)
    })

    const filteredResults = {};

    for (const assignee in results) {
      filteredResults[assignee] = results[assignee].length;
    }

    metricResultArray.push({
      _id: key,
      metric,
      ...filteredResults
    });
  }
  const projectsArray = [];
  projects.forEach(element => {
    projectsArray.push(element)
  })
  return { dataForGrid: metricResultArray, projectsArray };
}

export default SLOMetrics